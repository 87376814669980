<template>
  <div>
    <a-card>
      <div class="title">基础信息</div>
      <a-form-model
        ref="ruleForm"
        :form="form"
        :model="form"
        :rules="rules"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 21 }">
        <a-form-model-item label="视频名称" prop="ad_name">
          <a-input placeholder="请输入视频名称" v-model="form.ad_name" />
          <div class="small-style">
            （该名称在视频上传成功后，会作为视频标题显示在视频下方，请准确编辑，方便用户了解对应视频内容）
          </div>
        </a-form-model-item>

        <a-form-model-item label="视频图片" prop="ad_img" :wrapper-col="{ span: 12 }">
          <!-- <a-upload-dragger
            name="file"
            action="https://boss.yunguos.com/api/FolderFile/add"
            :before-upload="beforeUpload"
            :file-list="[]"
            :customRequest="handleChange">
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">点击或拖拽文件到此处上传</p>
            <p class="ant-upload-hint">支持JPG，png格式，建议尺寸1920 X 200 px</p>
          </a-upload-dragger>

          {{ file.name ? file.name : "" }} -->

          <a-upload
            :customRequest="handleChange"
            list-type="picture-card"
            :before-upload="beforeUpload"
            :show-upload-list="false">
            <img v-if="url" :src="url" class="avatar" />
            <div v-else>
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item label="视频链接" prop="ad_link">
          <a-input placeholder="请输入该视频图片绑定的视频链接地址" v-model="form.ad_link" />
        </a-form-model-item>

        <div class="title">展示配置</div>
        <a-form-model-item label="该视频是否展示在首页" prop="template_id">
          <a-radio-group v-model="form.is_recommend">
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
          </a-radio-group>
          <div class="small-style">
            （该配置主要用于控制视频上传后，是否推荐展示在首页帮助中心视频区域，【是】：展示在首页，【否】：不展示首页仅在视频库展示。)
          </div>
        </a-form-model-item>

        <a-form-model-item label="所属模块位置" prop="cate_id">
          <a-select placeholder="所属模块位置" v-model="form.cate_id" style="width: 300px">
            <a-select-option :value="1">收银端</a-select-option>
            <a-select-option :value="2">云果管家</a-select-option>
            <a-select-option :value="3">PC后台管理</a-select-option>
            <a-select-option :value="4">收银端AI操作</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-card>

    <a-card class="m-t-10">
      <a-row>
        <a-col :offset="3">
          <a-button type="primary" @click="handleOk" :loading="loading">提交</a-button>
          <a-button style="margin-left: 15px" @click="back()">取消</a-button>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { homeAdadd, homeAdedit, homeAddetail } from "@/views/api/system";
import { sign, randomString } from "../../utils/sign";
import { uploads } from "@/views/api/common";

import zhCn from "ant-design-vue/es/date-picker/locale/zh_CN";
import "moment/dist/locale/zh-cn";
import moment from "moment";

export default {
  data() {
    return {
      locale: zhCn,
      loading: false,
      params: "",
      form: {
        is_recommend: 0,
        ad_link: "",
        ad_img: "",
      },
      show_ad_link: 0,
      unit_list: [],
      url: "",
      area_list: [],
      key: [],
      publish_type: 0,
      sellerSource: [],
      seller_columns: [
        {
          title: "商户ID",
          dataIndex: "seller_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      file: {},

      rules: {
        ad_name: { required: true, message: "请输入视频名称", trigger: "blur" },
        ad_link: { required: true, message: "请输入三方链接", trigger: "blur" },
        cate_id: { required: true, message: "请选择所属模块位置", trigger: "blur" },
        ad_img: { required: true, message: "请上传图片", trigger: "blur" },
      },

      ad_id: 0,
    };
  },

  created() {
    if (this.$route.query.ad_id) {
      this.flag = 2;
      this.ad_id = this.$route.query.ad_id;
      this.homeAddetail();
    } else {
      this.flag = 1;
    }
  },

  methods: {
    moment,
    homeAddetail() {
      homeAddetail({ ad_id: this.ad_id }).then(res => {
        this.$delete(res.data, "create_time");
        this.form = res.data;
        this.url = res.data.ad_img_url;

        this.file.name = res.data.ad_img;
        // this.start_time = moment(res.data.start_time * 1000);
        // this.end_time = moment(res.data.end_time * 1000);

        // this.form.start_time = moment(this.start_time).unix();
        // this.form.end_time = moment(this.end_time).unix();

        this.$forceUpdate();
      });
    },

    add() {
      this.form.ad_type = 4;
      this.flag == 1
        ? homeAdadd(this.form).then(res => this.handleSuccess(1, res))
        : homeAdedit(this.form).then(res => this.handleSuccess(2, res));
    },

    // 取消 - 返回
    back() {
      this.$router.push({ path: "/abs", query: { type: 3 } });
    },

    // 上传前钩子
    beforeUpload(file) {
      console.log(file);
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("只能上传jpg及png文件!");
      }
      return isJpgOrPng;
    },

    // 变更
    // handleChange({ fileList }) {
    //   this.fileList = fileList;
    // },

    handleChange({ file }) {
      console.log(file);
      this.file.name = file.name;
      var data = {};
      const noncestr = randomString(20);
      const timestamp = Date.parse(new Date()) / 1000 + "";
      const token = sessionStorage.getItem("access_token");
      data.data = {
        noncestr: noncestr,
        timestamp: timestamp,
        user_token: token,
      };
      var params = new FormData();
      params.append("image", file);
      params.append("noncestr", noncestr);
      params.append("timestamp", timestamp);
      params.append("user_token", token);
      params.append("sign", sign(data));
      uploads(params).then(res => {
        if (res.code == 200) {
          this.$message.success("上传成功");
          this.form.ad_img = res.data.path;
          this.url = res.data.url;
          this.loading = false;
          this.$forceUpdate();
        }
      });
    },
    // 添加编辑
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          return false;
        } else {
          if (this.form.ad_name.length > 20) return this.$message.error("视频名称最多输入20位");
          this.add();
        }
      });
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "添加" : flag == 2 ? "编辑" : ""}成功`);
        this.visible = false;
        this.back();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ant-form-item-label {
  text-align: center !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}
.pic {
  width: 150px;
  height: 150px;
}
/deep/.anticon-delete {
  display: none;
}
/deep/.ant-upload-list-text {
  display: none;
}
/deep/ .hide {
  label {
    display: none;
  }
}

.small-style {
  color: #999;
}

/deep/.ant-input {
  width: 300px;
}

.modal {
  margin-left: 50%;
  transform: translateX(-50%);
  border: 1px solid #c8d9fe;
  background-color: #f6f8ff;
  padding: 10px 80px 10px 10px;
  width: 80%;
}
</style>
