import axios from "axios";
import { sign, randomString } from "./sign";
import { message } from "ant-design-vue";
const service = axios.create({
  baseURL: '', // url = base url + request url
  // baseURL: process.env.VUE_APP_API_BASE_URL, // url = base url + request url

  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 20000, // request timeout
});
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 清除空数据
    config.url = "/api" + config.url;
    
    for (let key in config.data) {
      if(config.data[key] === '' || config.data[key] === null || config.data[key] === undefined) delete config.data[key];
    }
    if (sessionStorage.getItem("access_token")) {
      config.data.user_token = sessionStorage.getItem("access_token");
    }
    config.data.noncestr = randomString(20);
    config.data.timestamp = Date.parse(new Date()) / 1000 + "";
    config.data.sign = sign(config.data);

    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    if (res.data.code == 403 || res.code == 403) {
      console.log(res.code);
      window.sessionStorage.clear();
      window.location.reload();
      return;
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.data.code == 400) {
      message.error(res.data.msg);
      // Message({
      //     message: res.message || "Error",
      //     type: "error",
      //     duration: 5 * 1000
      //   });
      //   Message({
      //     message: res.message || "Error",
      //     type: "error",
      //     duration: 5 * 1000
      //   });
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 404 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm('您已注销，您可以取消停留在此页面，或重新登录', '确认注销', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res.data;
    }
  },
  (error) => {
    // console.log("err" + error); // for debug
    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 5 * 1000
    // });
    return Promise.reject(error);
  }
);

export default service;
