<template>
  <div>
    <a-card>
      <div class="title">{{ params ? "编辑" : "添加" }}版本</div>
      <!-- 添加版本升级 -->
      <a-form-model
        ref="ruleForm"
        :form="form"
        :model="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 8, offset: 1 }">
        <a-form-model-item label="内部版本号" prop="version">
          <a-input placeholder="请输入内部版本号" v-model="form.version" show-word-limit />
        </a-form-model-item>

        <a-form-model-item label="外部版本号" prop="version_code">
          <a-input placeholder="请输入外部版本号" v-model="form.version_code" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="MD5" prop="version_md5">
          <a-input placeholder="请输入32位MD5" v-model="form.version_md5" :maxLength="32" />
        </a-form-model-item>
        <!-- <a-form-model-item label="下载地址" prop="download_url">
          <a-upload accept=".zip" :file-list="file" @change="beforeUploadpem($event)" :customRequest="before" :remove="remove">
            <a-button :loading="loading"> <a-icon type="upload" /> 选择文件 </a-button>
          </a-upload>
          <span style="margin-left: 20px" v-if="form.download_url">{{ form.download_url }}</span>
        </a-form-model-item> -->
        <!-- <a-form-model-item label="下载地址" prop="download_url">
          <a-input placeholder="请输入下载地址" v-model="form.download_url" show-word-limit />
        </a-form-model-item> -->
        <a-form-model-item label="升级内容" prop="update_desc">
          <a-textarea placeholder="请输入升级内容" v-model="form.update_desc" show-word-limit :auto-size="{ minRows: 4, maxRows: 10 }" />
        </a-form-model-item>

        <a-form-model-item label="发布类型" :wrapper-col="{ span: 20 }">
          <a-radio-group v-model="form.publish_type" @change="changeType">
            <a-radio :value="0">不限制</a-radio>
            <a-radio :value="1">按区域</a-radio>
            <a-radio :value="2">按商户</a-radio>
            <a-radio :value="4">按门店</a-radio>
            <a-radio :value="8">按机器</a-radio>
          </a-radio-group>

          <a-row v-if="form.publish_type != 0 && form.publish_type != 1">
            <a-button v-if="form.publish_type == 2" type="primary" style="margin-right: 10px" @click="showSellerModal">选择商户</a-button>
            <a-button v-if="form.publish_type == 4" type="primary" style="margin-right: 10px" @click="showStoreModal">选择门店</a-button>
            <a-button v-if="form.publish_type == 8" type="primary" style="margin-right: 10px" @click="showMachineModal">选择机器</a-button>

            <span v-if="version_id">
              <a-button type="primary" style="margin-right: 10px" @click="batchDel">批量删除</a-button>

              <a-select placeholder="请选择" v-model="seller_type">
                <a-select-option value="seller_id">商户ID</a-select-option>
                <a-select-option value="seller_name">商户名称</a-select-option>
              </a-select>
              <a-input placeholder="请输入" v-model="seller_value" style="width: 140px; margin-right: 10px"></a-input>

              <a-select v-if="form.publish_type == 4 || form.publish_type == 8" placeholder="请选择" v-model="store_type">
                <a-select-option value="store_id">门店ID</a-select-option>
                <a-select-option value="store_name">门店名称</a-select-option>
              </a-select>
              <a-input v-if="form.publish_type == 4 || form.publish_type == 8" placeholder="请输入" v-model="store_value" style="width: 140px; margin-right: 10px"></a-input>

              <a-select v-if="form.publish_type == 8" placeholder="请选择" v-model="machine_type">
                <a-select-option value="machine_id">机器ID</a-select-option>
              </a-select>
              <a-input v-if="form.publish_type == 8" placeholder="请输入" v-model="machine_value" style="width: 140px; margin-right: 10px"></a-input>

              <a-button @click="getList" @keyup.enter.native="getList" htmlType="submit">查询</a-button>
              <a style="margin-left: 8px" @click="clearParams">重置筛选条件</a>
            </span>

            <a-table
              bordered
              size="small"
              v-if="form.publish_type == 2"
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onChange, type: 'checkbox' }"
              :data-source="seller_list"
              :columns="seller_columns"
              :rowKey="record => record.seller_id"
              :pagination="
                version_id
                  ? {
                      current: page,
                      pageSize: limit,
                      total: total,
                      showLessItems: true,
                      showQuickJumper: true,
                      showTotal: (total, range) => `总计 ${total} 条`,
                      onChange: onPageChange,
                    }
                  : false
              ">
              <template slot="action" slot-scope="text, record">
                <a-popconfirm v-if="seller_list.length" title="是否确认删除?" @confirm="() => delSeller(record.seller_id)">
                  <a href="javascript:;">删除</a>
                </a-popconfirm>
              </template>
            </a-table>

            <a-table
              bordered
              size="small"
              v-if="form.publish_type == 4"
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onChange, type: 'checkbox' }"
              :data-source="store_list"
              :columns="store_columns"
              :rowKey="record => record.store_id"
              :pagination="{
                current: page,
                pageSize: limit,
                total: total,
                showLessItems: true,
                showQuickJumper: true,
                showTotal: (total, range) => `总计 ${total} 条`,
                onChange: onPageChange,
              }">
              <template slot="action" slot-scope="text, record">
                <a-popconfirm v-if="store_list.length" title="是否确认删除?" @confirm="() => delStore(record.store_id)">
                  <a href="javascript:;">删除</a>
                </a-popconfirm>
              </template>
            </a-table>

            <a-table
              bordered
              size="small"
              v-if="form.publish_type == 8"
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onChange, type: 'checkbox' }"
              :data-source="machine_list"
              :columns="machine_columns"
              :rowKey="record => record.auto_id"
              :pagination="
                version_id
                  ? {
                      current: page,
                      pageSize: limit,
                      total: total,
                      showLessItems: true,
                      showQuickJumper: true,
                      showTotal: (total, range) => `总计 ${total} 条`,
                      onChange: onPageChange,
                    }
                  : false
              ">
              <template slot="action" slot-scope="text, record">
                <a-popconfirm v-if="machine_list.length" title="是否确认删除?" @confirm="() => delMachine(record.auto_id)">
                  <a href="javascript:;">删除</a>
                </a-popconfirm>
              </template>
            </a-table>
          </a-row>
        </a-form-model-item>

        <a-form-model-item v-if="form.publish_type == 1" label="指定区域" prop="publish_area" :wrapper-col="{ span: 10 }">
          <a-checkbox-group v-model="form.publish_area">
            <span v-for="item in area_list" :key="item.area_id">
              <a-checkbox :value="item.area_id" style="margin: 10px 15px 10px 0">
                {{ item.area_name }}
              </a-checkbox>
            </span>
          </a-checkbox-group>
        </a-form-model-item>

        <a-form-model-item v-if="form.publish_type == 2" label="指定商户" prop="publish_seller">
          <a-input placeholder="请点击选择/查看" show-word-limit @click="showModal" />
        </a-form-model-item>
        <a-form-model-item label="hide" prop="seller_id" ref="seller_id" class="hide" v-if="sellerSource.length > 0">
          <a-table
            bordered
            :data-source="sellerSource"
            :columns="seller_columns"
            :pagination="false"
            :scroll="{ y: 266 }">
            <template slot="action" slot-scope="text, record">
              <a-popconfirm
                v-if="sellerSource.length"
                title="是否确认删除?"
                @confirm="() => sellerOnDelete(record.seller_id)">
                <a href="javascript:;">删除</a>
              </a-popconfirm>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item label="强制升级">
          <a-radio-group v-model="form.force_update">
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="启用状态">
          <a-radio-group v-model="form.status">
            <a-radio :value="0">禁用</a-radio>
            <a-radio :value="1">启用</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-card>

    <a-card class="m-t-10">
      <a-row>
        <a-col :offset="3">
          <a-button type="primary" @click="handleOk" :loading="loading">提交</a-button>
          <a-button style="margin-left: 15px" @click="back()">取消</a-button>
        </a-col>
      </a-row>
    </a-card>

    <seller-modal ref="sellerModal" @func="getSeller"></seller-modal>
    <store-modal ref="storeModal" @func="getStore"></store-modal>
    <machine-modal ref="machineModal" @func="getMachine"></machine-modal>
  </div>
</template>

<script>
import { versionAdd, versionEdit, Versioninfo, versionMachine, addMachine, delMachine, versionStore, addStore, delStore, versionSeller, addSeller, delSeller } from "@/views/api/system";
import { SellerAreaList } from "@/views/api/seller";
import sellerModal from "./version-modal/seller-modal.vue";
import storeModal from "./version-modal/store-modal.vue";
import machineModal from "./version-modal/machine-modal.vue";
import moment from "moment";

export default {
  components: { sellerModal, storeModal, machineModal },
  data() {
    return {
      visible: false,
      loading: false,
      params: "",
      form: {
        publish_area: [],
        status: 1,
        force_update: 1,
        publish_type: 8,
      },
      unit_list: [],
      url: "",
      area_list: [],
      key: [],
      file: [],
      seller_list: [],
      seller_columns: [
        {
          title: "商户ID",
          dataIndex: "seller_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      store_columns: [
        {
          title: "门店ID",
          dataIndex: "store_id",
        },
        {
          title: "门店名称",
          dataIndex: "store_name",
        },
        {
          title: "商户ID",
          dataIndex: "seller_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "经营方式",
          dataIndex: "store_type",
          customRender: text => {
            return text == 1 ? "直营" : text == 2 ? "加盟" : "";
          },
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          customRender: text => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "到期时间",
          dataIndex: "expire_time",
          customRender: text => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      store_list: [],

      machine_columns: [
        {
          title: "序号",
          dataIndex: "auto_id",
        },
        {
          title: "商户ID",
          dataIndex: "seller_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "门店名称",
          dataIndex: "store_name",
        },
        {
          title: "门店ID",
          dataIndex: "store_id",
        },
        {
          title: "机器ID",
          dataIndex: "machine_id",
        },
        {
          title: "机器版本",
          dataIndex: "edition",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      machine_list: [],

      search_params: {},
      page: 1,
      limit: 10,
      total: 0,

      seller_type: "seller_id",
      seller_value: "",
      store_type: "store_id",
      store_value: "",
      machine_type: "machine_id",
      machine_value: "",

      selectedRows: [],
      selectedRowKeys: [],

      version_id: 0,
      sellerSource: []
    };
  },
  computed: {},
  methods: {
    moment,

    SellerAreaList() {
      SellerAreaList({}).then(res => {
        this.area_list = res.data;
      });
    },

    Versioninfo() {
      Versioninfo({ version_id: this.version_id }).then(res => {
        this.form = res.data;
        this.form.publish_type = res.data.publish_type;
        if (res.data.publish_area) this.form.publish_area = res.data.publish_area.map(Number);
        if (res.data.publish_type == 8) this.publish_machine = res.data.publish_ids;
        if (res.data.publish_type == 4) this.publish_store = res.data.publish_ids;
        if (res.data.publish_type == 2) this.publish_seller = res.data.publish_ids;
        this.getList();
      });
    },

    onPageChange(page, pageSize) {
      this.page = page;
      this.pageSize = pageSize;
      if (this.form.publish_type == 8) this.getVersionMachine(page, pageSize);
      if (this.form.publish_type == 4) this.getVersionStore(page, pageSize);
      if (this.form.publish_type == 2) this.getVersionSeller(page, pageSize);
    },

    onChange(keys) {
      this.selectedRowKeys = keys;
      // if (keys.length == rows.length) {
      //   this.selectedRows = rows;
      // } else {
      //   this.selectedRows = keys.map(key => this.selectedRows.find(row => row.store_id == key) || rows.find(row => row.store_id == key));
      // }
    },

    batchDel() {
      if (this.selectedRowKeys.length == 0) return this.$message.error("您还未选择，请先选择");
      if (this.form.publish_type == 8) this.delMachine();
      if (this.form.publish_type == 4) this.delStore();
      if (this.form.publish_type == 2) this.delSeller();
    },

    getVersionSeller(page, limit) {
      versionSeller({ ...this.search_params, version_id: this.version_id, page: page, limit: limit }).then(res => {
        this.seller_list = res.data.list;
        this.total = res.data.count;
        this.publish_seller = res.data.selected;
      });
    },

    getVersionMachine(page, limit) {
      versionMachine({ ...this.search_params, version_id: this.version_id, page: page, limit: limit }).then(res => {
        this.machine_list = res.data.list;
        this.total = res.data.count;
        this.publish_machine = res.data.selected;
      });
    },

    getVersionStore(page, limit) {
      versionStore({ ...this.search_params, version_id: this.version_id, page: page, limit: limit }).then(res => {
        this.store_list = res.data.list;
        this.total = res.data.count;
        this.publish_store = res.data.selected;
      });
    },

    getList() {
      console.log("in");
      this.search_params.seller_name = "";
      this.search_params.store_name = "";
      this.search_params.machine_name = "";
      this.search_params[this.seller_type] = this.seller_value;
      this.search_params[this.store_type] = this.store_value;
      this.search_params[this.machine_type] = this.machine_value;
      if (this.form.publish_type == 8) this.getVersionMachine(1, 10);
      if (this.form.publish_type == 4) this.getVersionStore(1, 10);
      if (this.form.publish_type == 2) this.getVersionSeller(1, 10);
      this.form.publish_area = [];
    },

    clearParams() {
      this.seller_value = "";
      this.store_value = "";
      this.machine_value = "";
      this.search_params = {};
      this.page = 1;
      this.limit = 10;
      this.search_params.seller_type = "seller_id";
      this.search_params.machine_type = "machine_id";
      this.search_params.store_type = "store_id";
      this.getList();
    },

    changeType() {
      this.form.publish_seller = [];
      this.form.publish_store = [];
      this.form.publish_machine = [];
      this.seller_list = [];
      this.store_list = [];
      this.machine_list = [];
      this.getList();
    },

    // 调用商户组件
    showSellerModal() {
      this.$refs.sellerModal.childMethod(this.publish_seller || [], this.seller_list || []);
    },

    // 调用门店组件
    showStoreModal() {
      this.$refs.storeModal.childMethod(this.publish_store || [], this.store_list || []);
    },

    // 调用机器组件
    showMachineModal() {
      this.$refs.machineModal.childMethod(this.publish_machine || [], this.machine_list || []);
    },

    // 获取->添加商户
    getSeller(seller_list, seller_id_list) {
      if (!this.version_id) {
        this.form.publish_seller = seller_id_list;
        this.publish_seller = seller_id_list;
        this.seller_list = seller_list;
        return;
      }
      const new_id_list = this.removeCommonValues(seller_id_list || [], this.publish_seller || []);
      addSeller({ seller_ids: new_id_list, version_id: this.version_id }).then(res => {
        if (res.code == 200) {
          this.publish_seller = seller_id_list;
          this.getList();
        }
      });
    },

    // 获取->添加门店
    getStore(store_list, store_id_list) {
      if (!this.version_id) {
        this.form.publish_store = store_id_list;
        this.publish_store = store_id_list;
        this.store_list = store_list;
        return;
      }
      const new_id_list = this.removeCommonValues(store_id_list || [], this.publish_store || []);
      addStore({ store_ids: new_id_list, version_id: this.version_id }).then(res => {
        if (res.code == 200) {
          this.publish_store = store_id_list;
          this.getList();
        }
      });
    },

    // 获取->添加机器
    getMachine(machine_list, machine_id_list) {
      if (!this.version_id) {
        this.form.publish_machine = machine_id_list;
        this.publish_machine = machine_id_list;
        this.machine_list = machine_list;
        return;
      }
      const new_id_list = this.removeCommonValues(machine_id_list || [], this.publish_machine || []);
      addMachine({ machine_ids: new_id_list, version_id: this.version_id }).then(res => {
        if (res.code == 200) this.publish_machine = machine_id_list;
        this.getList();
      });
    },

    // 删除商户
    delSeller(seller_id) {
      const params = { version_id: this.version_id, seller_ids: seller_id ? [seller_id] : this.selectedRowKeys };
      delSeller(params).then(res => {
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getVersionSeller(1, 10);
          this.page = 1;
        }
      });
    },

    // 删除门店
    delStore(store_id) {
      const params = { version_id: this.version_id, store_ids: store_id ? [store_id] : this.selectedRowKeys };
      delStore(params).then(res => {
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getVersionStore(1, 10);
          this.page = 1;
        }
      });
    },

    // 删除机器
    delMachine(machine_id) {
      const params = { version_id: this.version_id, machine_ids: machine_id ? [machine_id] : this.selectedRowKeys };
      delMachine(params).then(res => {
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getVersionMachine(1, 10);
          this.page = 1;
        }
      });
    },

    // 过滤两数组相同的值
    removeCommonValues(arr1, arr2) {
      return arr1.filter(value => !arr2.includes(value));
    },

    // 添加编辑
    handleOk() {
      // if (this.area_list.length == this.form.publish_area.length) this.form.publish_area = [];
      // this.publish_type == 1 ? (this.form.publish_area = []) : (this.form.publish_seller = []);
      this.flag == 1
        ? versionAdd(this.form).then(res => this.handleSuccess(1, res))
        : versionEdit(this.form).then(res => this.handleSuccess(2, res));
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "添加" : flag == 2 ? "编辑" : ""}成功`);
        this.visible = false;
        this.back();
      }
    },

    // 取消
    back() {
      this.$router.go(-1);
    },
  },

  created() {
    this.SellerAreaList();
    if (this.$route.query.version_id) {
      this.flag = 2;
      this.version_id = this.$route.query.version_id;
      this.Versioninfo();
    } else {
      this.flag = 1;
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-select {
  width: 100px;
}
</style>
