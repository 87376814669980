import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";
import Home from "../views/Home.vue";
import Homepage from "../views/src/Homepage.vue";
import seller from "../views/src/seller/seller.vue";
import addSeller from "../views/src/seller/addSeller.vue";
import goodsUnit from "../views/src/system/goodsUnit.vue";
import warehouseCate from "../views/src/system/warehouseCate.vue";
import sms from "../views/src/system/sms.vue";
import abs from "../views/src/system/abs.vue";
import wxpath from "../views/src/system/wxpath.vue";
import errorLog from "../views/src/system/errorLog.vue";
import version from "../views/src/system/version.vue";
import folderCate from "../views/src/system/folderCate.vue";
import product from "../views/src/system/product.vue";
import express from "../views/src/system/express.vue";
import bank from "../views/src/system/bank.vue";
import notice from "../views/src/system/notice.vue";
import user from "../views/src/system/user.vue";
import payment from "../views/src/system/payment.vue";
import machine from "../views/src/seller/machine.vue";
import store from "../views/src/seller/store.vue";
import goods from "../views/src/seller/goods.vue";
import sellerNode from "../views/src/seller/sellerNode.vue";
import sellerRole from "../views/src/seller/sellerRole.vue";
import roleAdd from "../views/src/seller/roleAdd.vue";
import addGoods from "../views/src/seller/addGoods.vue";
import sellerArea from "../views/src/seller/sellerArea.vue";
import addVersion from "../views/src/system/addVersion.vue";
import versionXcx from "../views/src/system/versionXcx.vue";
import addVersionXcx from "../views/src/system/addVersionXcx.vue";
import orderStore from "../views/src/order/orderStore.vue";
import orderSms from "../views/src/order/orderSms.vue";
import sellerRenew from "../views/src/seller/sellerRenew.vue";
import sellerRenewlist from "../views/src/seller/sellerRenewlist.vue";
import agent from "../views/src/agent/agent.vue";
import agentAdd from "../views/src/agent/agentAdd.vue";
import addSellerRenew from "../views/src/seller/addSellerRenew.vue";
import addHomeAds from "../views/src/system/addHomeAds.vue";
import addHomeVideoAds from "../views/src/system/addHomeVideoAds.vue";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    redirect: "/Homepage",
    children: [
      // 首页
      { path: "/Homepage", component: Homepage },
      // 商户列表
      { path: "/seller", component: seller },
      // 添加商户
      { path: "/addSeller", component: addSeller },
      // 商品单位
      { path: "/goodsUnit", component: goodsUnit },
      // 出入库类型
      { path: "/warehouseCate", component: warehouseCate },
      // 短信套餐
      { path: "/sms", component: sms },
      // 广告管理
      { path: "/abs", component: abs },
      // 导航路径
      { path: "/wxpath", component: wxpath },
      // 收银机异常日志
      { path: "/errorLog", component: errorLog },
      // 版本升级
      { path: "/version", component: version },
      // 线上图库
      { path: "/folderCate", component: folderCate },
      // 产品列表
      { path: "/product", component: product },
      // 快递公司
      { path: "/express", component: express },
      // 开户行
      { path: "/bank", component: bank },
      // 通知模板
      { path: "/notice", component: notice },
      // 系统用户
      { path: "/user", component: user },
      // 支付方式
      { path: "/payment", component: payment },
      // 收银机器
      { path: "/machine", component: machine },
      // 门店管理
      { path: "/store", component: store },
      // 商品库
      { path: "/goods", component: goods },
      // 商家列表
      { path: "/sellerNode", component: sellerNode },
      // 商家角色
      { path: "/sellerRole", component: sellerRole },
      // 商家角色添加编辑
      { path: "/roleAdd", component: roleAdd },
      // 添加商品
      { path: "/addGoods", component: addGoods },
      // 升级区域
      { path: "/sellerArea", component: sellerArea },
      // 添加升级版本
      { path: "/addVersion", component: addVersion },
      // 小程序升级版本
      { path: "/versionXcx", component: versionXcx },
      // 小程序升级
      { path: "/addVersionXcx", component: addVersionXcx },
      // 订单
      { path: "/orderStore", component: orderStore },
      // 短信
      { path: "/orderSms", component: orderSms },
      // 续费列表
      { path: "/sellerRenew", component: sellerRenew },
      // 添加续费
      { path: "/addSellerRenew", name: "addSellerRenew", component: addSellerRenew },
      // 续费记录
      { path: "/sellerRenewlist", component: sellerRenewlist },
      // 代理商
      { path: "/agent", component: agent },
      // 新增代理商
      { path: "/agentAdd", component: agentAdd },
      // 新增代理商
      { path: "/addHomeAds", component: addHomeAds },
      // 新增代理商
      { path: "/addHomeVideoAds", component: addHomeVideoAds },
    ],
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];
console.log(routes);
const router = new VueRouter({
  routes,
});

// // 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转

  if (to.path == "/login") {
    window.sessionStorage.clear();
    next();
  }
  // 获取token
  const tokenStr = window.sessionStorage.getItem("access_token");
  if (!tokenStr) return next("/login");
  next();
});

export default router;
