<template>
  <div>
    <a-card>
      <div class="title">基础信息</div>
      <a-form-model
        ref="ruleForm"
        :form="form"
        :model="form"
        :rules="rules"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 21 }">
        <a-form-model-item label="广告名称" prop="ad_name">
          <a-input placeholder="请输入广告名称" v-model="form.ad_name" />
        </a-form-model-item>

        <a-form-model-item label="广告图片" prop="ad_img" :wrapper-col="{ span: 12 }">
          <!-- <a-upload-dragger
            name="file"
            action="https://boss.yunguos.com/api/FolderFile/add"
            :before-upload="beforeUpload"
            :file-list="[]"
            :customRequest="handleChange">
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">点击或拖拽文件到此处上传</p>
            <p class="ant-upload-hint">支持JPG，png格式，建议尺寸1920 X 200 px</p>
          </a-upload-dragger>

          {{ file.name ? file.name : "" }} -->

          <a-upload
            :customRequest="handleChange"
            list-type="picture-card"
            :before-upload="beforeUpload"
            :show-upload-list="false">
            <img v-if="url" :src="url" class="avatar" />
            <div v-else>
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item label="是否支持链接跳转">
          <a-radio-group v-model="show_ad_link">
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
            <span class="small-style">（该配置主要用于广告页图片是否支持关联三方链接跳转~）</span>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="三方链接" prop="ad_link" v-if="show_ad_link">
          <a-input placeholder="请输入/复制你想要跳转的链接页面" v-model="form.ad_link" />
        </a-form-model-item>

        <div class="title">展示时间</div>
        <a-form-model-item
          label="开始时间"
          prop="start_time"
          :label-col="{ span: 10 }"
          :wrapper-col="{ span: 12 }"
          style="display: inline-block; width: 30%">
          <a-date-picker
            :locale="locale"
            :allowClear="false"
            show-time
            v-model="start_time"
            placeholder="请选择"
            @change="onStartChange" />
        </a-form-model-item>

        <a-form-model-item
          label="结束时间"
          prop="end_time"
          :label-col="{ span: 10 }"
          :wrapper-col="{ span: 12 }"
          style="display: inline-block; width: 30%">
          <a-date-picker
            :locale="locale"
            :allowClear="false"
            show-time
            v-model="end_time"
            placeholder="请选择"
            @change="onEndChange" />
        </a-form-model-item>

        <div class="title">展示控制</div>
        <a-form-model-item label="允许用户关闭" prop="template_id">
          <a-radio-group v-model="form.is_close">
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
          </a-radio-group>
          <div class="small-style">
            （该配置主要用于用户是否可以在首页自主关闭广告页。【是】：广告页面显示“关闭按钮”用户可以手动关闭，【否】：用户无法关闭，该广告只能根据系统设置时间自动关闭。）
          </div>
        </a-form-model-item>

        <a-form-model-item label="关闭后是否再次展示" prop="template_id" v-if="form.is_close">
          <a-radio-group v-model="form.is_show">
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
          </a-radio-group>
          <div class="small-style">（该配置主要用于用户自主关闭首页广告后，次日登录系统是否继续展示广告页）</div>
        </a-form-model-item>
      </a-form-model>
    </a-card>

    <a-card class="m-t-10">
      <a-row>
        <a-col :offset="3">
          <a-button type="primary" @click="handleOk" :loading="loading">提交</a-button>
          <a-button style="margin-left: 15px" @click="back()">取消</a-button>
        </a-col>
      </a-row>
    </a-card>

    <a-modal v-model="visible" title="提示" centered @ok="add" @cancel="visible = false">
      <p style="text-align: center">你确定要发布吗？</p>

      <div class="modal">
        <p>
          广告生效时间: <span> {{ last_time > moment(start_time).unix() ? "立即生效" : "定时生效" }} </span>
        </p>
        <p>开始时间: {{ moment(start_time).format("YYYY-MM-DD") }}</p>
        <p>结束时间: {{ moment(end_time).format("YYYY-MM-DD") }}</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { homeAdadd, homeAdedit, homeAddetail } from "@/views/api/system";
import { sign, randomString } from "../../utils/sign";
import { uploads } from "@/views/api/common";
import zhCn from "ant-design-vue/es/date-picker/locale/zh_CN";
import "moment/dist/locale/zh-cn";
import moment from "moment";

export default {
  data() {
    return {
      locale: zhCn,
      visible: false,
      loading: false,
      params: "",
      form: {
        is_close: 1,
        is_show: 1,
        start_time: undefined,
        end_time: undefined,
        ad_link: "",
        ad_img: "",
      },
      show_ad_link: 0,
      unit_list: [],
      url: "",
      area_list: [],
      key: [],
      publish_type: 0,
      sellerSource: [],
      seller_columns: [
        {
          title: "商户ID",
          dataIndex: "seller_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      file: {},

      start_time: undefined,
      end_time: undefined,

      rules: {
        ad_name: { required: true, message: "请输入广告名称", trigger: "blur" },
        ad_link: { required: true, message: "请输入三方链接", trigger: "blur" },
        ad_img: { required: true, message: "请上传图片", trigger: "blur" },
        start_time: { required: true, message: "请选择开始时间", trigger: "change" },
        end_time: { required: true, message: "请选择结束时间", trigger: "change" },
      },

      ad_id: 0,

      last_time: "",
    };
  },

  created() {
    if (this.$route.query.ad_id) {
      this.flag = 2;
      this.ad_id = this.$route.query.ad_id;
      this.homeAddetail();
    } else {
      this.flag = 1;
    }
  },

  methods: {
    moment,
    homeAddetail() {
      homeAddetail({ ad_id: this.ad_id }).then(res => {
        this.$delete(res.data, "create_time");
        this.form = res.data;
        this.url = res.data.ad_img_url;
        res.data.ad_link ? (this.show_ad_link = 1) : (this.show_ad_link = 0);

        this.file.name = res.data.ad_img;
        this.start_time = moment(res.data.start_time * 1000);
        this.end_time = moment(res.data.end_time * 1000);

        this.form.start_time = moment(this.start_time).unix();
        this.form.end_time = moment(this.end_time).unix();

        this.$forceUpdate();
      });
    },

    add() {
      this.show_ad_link ? (this.form.ad_type = 2) : (this.form.ad_type = 1);
      this.flag == 1
        ? homeAdadd(this.form).then(res => this.handleSuccess(1, res))
        : homeAdedit(this.form).then(res => this.handleSuccess(2, res));
    },

    // 取消 - 返回
    back() {
      this.$router.push({ path: "/abs", query: { type: 2 } });
    },

    // 添加编辑
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          return false;
        } else {
          if (this.form.ad_name.length > 10) return this.$message.error("广告名称最多输入10位");
          this.visible = true;
          this.last_time = moment().unix();
        }
      });
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "添加" : flag == 2 ? "编辑" : ""}成功`);
        this.visible = false;
        this.back();
      }
    },

    // 上传前钩子
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("只能上传jpg及png文件!");
      }
      return isJpgOrPng;
    },

    // 变更
    // handleChange({ fileList }) {
    //   this.fileList = fileList;
    // },

    // handleChange({ file }) {
    //   this.loading = true;
    //   getBase64(file, async imageUrl => {
    //     var params = { name: file.name, url: imageUrl };
    //     uploads(params).then(res => {
    //       if (res.code == 200) {
    //         this.$message.success("上传成功");
    //         this.file.name = file.name;
    //         this.file.url = res.data.url;
    //         this.form.ad_img = res.data.path;
    //         this.loading = false;
    //       }
    //     });
    //     this.$forceUpdate();
    //   });
    // },

    handleChange({ file }) {
      console.log(file);
      this.file.name = file.name;
      var data = {};
      const noncestr = randomString(20);
      const timestamp = Date.parse(new Date()) / 1000 + "";
      const token = sessionStorage.getItem("access_token");
      data.data = {
        noncestr: noncestr,
        timestamp: timestamp,
        user_token: token,
      };
      var params = new FormData();
      params.append("image", file);
      params.append("noncestr", noncestr);
      params.append("timestamp", timestamp);
      params.append("user_token", token);
      params.append("sign", sign(data));
      uploads(params).then(res => {
        if (res.code == 200) {
          this.$message.success("上传成功");
          this.form.ad_img = res.data.path;
          this.url = res.data.url;
          this.loading = false;
          this.$forceUpdate();
        }
      });
    },

    // beforeUploadpem(file) {
    //   this.loading = true;
    //   file.file.status = "done";

    //   this.file = [file.file];
    //   var data = {};
    //   const noncestr = randomString(20);
    //   const timestamp = Date.parse(new Date()) / 1000 + "";
    //   const token = sessionStorage.getItem("access_token");
    //   data.data = {
    //     noncestr: noncestr,
    //     timestamp: timestamp,
    //     user_token: token,
    //   };
    //   var params = new FormData();
    //   params.append("file", file.file.originFileObj);
    //   params.append("noncestr", noncestr);
    //   params.append("timestamp", timestamp);
    //   params.append("user_token", token);
    //   params.append("user_token", 1);
    //   params.append("name", file.file.name);
    //   params.append("sign", sign(data));
    //   upload(params)
    //     .then(res => {
    //       if (res.code == 200) {
    //         this.$message.success("上传成功");
    //         this.form.download_url = res.data;
    //         this.loading = false;
    //       }
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    //   this.$forceUpdate();
    // },

    onStartChange(date, dateString) {
      this.start_time = moment(dateString);
      this.sendTime();
    },

    onEndChange(date, dateString) {
      this.end_time = moment(dateString);
      this.sendTime();
    },

    // onChangetime(date, dateString) {
    //   this.start_time = moment(dateString[0]);
    //   this.end_time = moment(dateString[1]);
    //   this.sendTime();
    // },

    sendTime() {
      if (moment(this.start_time).unix() > moment(this.end_time).unix()) {
        this.end_time = undefined;
        return this.$message.error("开始时间不能大于结束时间");
      }

      this.form.start_time = moment(this.start_time).unix();
      this.form.end_time = moment(this.end_time).unix();
    },
  },
};
</script>

<style lang="less" scoped>
.ant-form-item-label {
  text-align: center !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}
.pic {
  width: 150px;
  height: 150px;
}
/deep/.anticon-delete {
  display: none;
}
/deep/.ant-upload-list-text {
  display: none;
}
/deep/ .hide {
  label {
    display: none;
  }
}

.small-style {
  color: #999;
}

/deep/.ant-input {
  width: 300px;
}

.modal {
  margin-left: 50%;
  transform: translateX(-50%);
  border: 1px solid #c8d9fe;
  background-color: #f6f8ff;
  padding: 10px 80px 10px 10px;
  width: 80%;
}
</style>
