var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" 广告名称: "),_c('a-input',{staticStyle:{"width":"220px","margin-right":"20px"},attrs:{"placeholder":"请输入广告名称"},model:{value:(_vm.searchParams.ad_name),callback:function ($$v) {_vm.$set(_vm.searchParams, "ad_name", $$v)},expression:"searchParams.ad_name"}}),_vm._v(" 状态: "),_c('a-select',{staticStyle:{"width":"180px","margin-right":"20px"},attrs:{"placeholder":"请选择状态"},model:{value:(_vm.searchParams.status),callback:function ($$v) {_vm.$set(_vm.searchParams, "status", $$v)},expression:"searchParams.status"}},[_c('a-select-option',{attrs:{"value":0}},[_vm._v("全部")]),_c('a-select-option',{attrs:{"value":1}},[_vm._v("生效中")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("未生效")]),_c('a-select-option',{attrs:{"value":4}},[_vm._v("已结束")])],1),_vm._v(" 创建时间: "),_c('a-date-picker',{staticStyle:{"width":"200px"},attrs:{"locale":_vm.locale,"allowClear":false,"placeholder":"请选择"},on:{"change":_vm.onStartChange},model:{value:(_vm.start_time),callback:function ($$v) {_vm.start_time=$$v},expression:"start_time"}}),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.getList(true)}}},[_vm._v("查询")]),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.reSearch()}}},[_vm._v("重置")])],1),_c('a-button',{attrs:{"type":"primary","icon":"plus"},on:{"click":_vm.add}},[_vm._v("新增广告")]),_c('a-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.dataList,"rowKey":function (record) { return record.ad_id; },"tableLayout":"fixed","pagination":{
      current: _vm.searchParams.page,
      pageSize: _vm.searchParams.limit,
      total: _vm.total,
      showLessItems: true,
      showQuickJumper: true,
      showTotal: function (total, range) { return ("总计 " + total + " 条"); },
      onChange: _vm.onPageChange,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50'],
      onShowSizeChange: _vm.onPageChange,
    }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [(record.status == 1)?_c('a-popconfirm',{attrs:{"title":"确定终止该广告吗?","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.homeAdstop(record.ad_id)}}},[_c('a',{staticStyle:{"margin-right":"10px"}},[_vm._v("手动结束")])]):_vm._e(),(record.status != 4)?_c('a',{staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.edit(record.ad_id)}}},[_vm._v("编辑")]):_vm._e(),_c('a-popconfirm',{attrs:{"title":"确定删除该广告吗?","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.confirm(record.ad_id)}}},[_c('a',{staticClass:"error-color"},[_vm._v("删除")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }